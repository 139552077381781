@import './assets/css/bootstrap.min.css';
@import './assets/fonts/fontawesome/css/all.min.css';
@import './assets/css/style.css';
@import './assets/css/responsive.css';



.maixmo-sel .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    border-width: 6px 5px 0 !important;
    border-color: #898cb1 transparent !important;
}

.maixmo-sel .dropdown-list ul li {
    display: block;
    margin: 0;
}

.maixmo-sel .multiselect-dropdown .dropdown-list {
    border-radius: 0;
    box-shadow: 0 3px 5px #00000047;
    border: 1px solid #ddd;
    padding: 0;
    margin: 6px 0 0;
}

.maixmo-sel .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox] + div:before {
    color: #2f3071 !important;
    border-color: #2f3071 !important;
}

.maixmo-sel .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
    background: #2f3071 !important;
}

.maixmo-sel .multiselect-dropdown .multiselect-dropdown .dropdown-btn .selected-item a {
    margin-left: 5px;
}
.dropdown-list li.filter-textbox {
    display: none !important;
}
.pop-foot .dropdown-list ul {
    border-top: 0;
}


.maixmo-sel .multiselect-dropdown .dropdown-btn .selected-item {
    border-color: #2f3071 !important;
    background: #2f3071 !important;
    max-width: inherit !important;
    font-size: 12px;
    padding: 3px 10px !important;
}

.ser-maixmo-sel {
    width: 100%;
    padding: 20px 20px;
    select, input {
    width: 100%;
    cursor: pointer;
    display: block;
    background-clip: padding-box;
    border: 1px solid #c1c1d2;
    border-radius: 8px;
    flex: 1 0 0%;
    min-width: 0px;
    padding: 14px 14px;
    z-index: 3;
        &[readonly="true"] {
            background: #f5f5f5;
            cursor: not-allowed;
        }
    }

}
.ser-maixmo-sel {
    ul {
        padding: 0;
        margin: 0 -5px;
        display:flex;
        li {
            display: block;
            text-align: left;
            width:33.3%;
            padding: 0 5px;
            label {
                display: block;
                position: relative;
                margin: 0;
                padding: 10px 0;
                cursor: pointer;
                input[type="checkbox"] {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    & ~ span {
                        display: block;
                        position: relative;
                        padding-left: 35px;
                        i {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            border: 1px solid #ccc;
                            background: #fff;
                            border-radius: 3px;
                            padding: 3px 6px;
                            &:after {
                                content: '';
                                display: block;
                                width: 6px;
                                height: 10px;
                                border-right: 2px solid #fff;
                                border-bottom: 2px solid #fff;
                                transform: rotate(45deg);
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                    }
                    &:checked {
                        & ~ span {
                            i {
                                background: #2f3071;
                                border-color: #2f3071;
                                &:after {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
            & + li {
                /* border-top: 1px solid #ddd; 
               margin: 0 12px;*/
            }
            & + li:nth-last-child(1){
            display: flex;
            .single-fld:nth-last-child(2){
            width:70%
            }
            .single-fld:nth-last-child(1){
            width:30%
            }
            }

        }
    }

}
.single-fld{
    button{
        padding: 13px;
        border: 1px solid #2f3071;
        border-radius: 8px;
        width: 100%;
        margin-left: 12px;
        background: #2f3071;
        color: #fff;
        box-shadow: 0 2px 6px #2f307170;
    }
}

.user-profile{
    .noty-body {
    position: absolute;
    right: -29px;
    top: 44px;
    background: white;
    box-shadow: 0 0 3px 0 #cfc9c9;
    border-radius: 4px;
    padding: 0;
    width: 155px;
    z-index: 55;
     ul{
        padding-left: 0;
        list-style: none;
        .logout-btn {
            padding: 11px 15px;
            h2 {
                margin: 0;
            }
            &  + .logout-btn {
                border-top: 1px solid #eee;
            }
        }
    }
   
    }
   
}


.txt{
    h2{
        font-size: 14px;
        text-align: left;
        color: #2f3071;
        margin-bottom: 15px; 
    }
    h2:hover{
        opacity: .7;
    }
}


.croess {
    position: absolute;
    top: -13px;
    right: -10px;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    color: rgb(47 48 113);
    box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    cursor: pointer;
}

.delete-loc {
    span {
        display: block;
        width: 30px;
        height: 20px;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
        color: #f00;
    }
}


.user-prof-inner {
    background: #383880;
    position: relative;
    padding: 2px;
    padding-left: 30px;
    border-radius: 40px;
    & > span {
        position: absolute;
        top: 50%;
        left: 11px;
        width: 12px;
        height: 18px;
        line-height: 18px;
        margin-top: -9px;
    }
}

.overlay-pop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    
}


.left-block .navlink ul > li a:not(.active) { cursor: pointer}



.top-nav {
    margin-left: 0px !important; 
    transition: all 0.2s ease 0s;
}
.home-list {
    > h4 {
        font-size: 15px;
        margin: 0 0 10px;
        color: #2f3071;
        border: 1px solid #eee;
        box-shadow: 0 3px 10px rgb(0 0 0 / 5%);
        padding: 16px;
        background: #f6f6ff;
    }
    & + .home-list {
        padding-top: 25px;
    }
}

.home-list-single {
    & > ul {
        padding: 0;
        margin: 0 -5px;
        display: flex;
        flex-wrap: wrap;
        & > li {
            width: 50%;
            padding: 5px;
            display: block;
        }
    }
}

.nav-logo {
    padding: 37px 5px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 220px;
    z-index: 1;
    background: rgb(255, 255, 255);
    text-align: center;
    height: 75px;
    a {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        img.logo {
            width: 190px;
            max-width: inherit;
        }
        img.logo-icon {
            width: 50px;
        }
    }
}
.left-item { padding-left: 220px}

@media screen and (min-width: 992px) {
    .nav-logo {
        a {
            img.logo-icon {
                display: none;  
            }
        }
    }

}


@media screen and (max-width: 991px) {
    .user-profile .noty-body {
        right: -4px;
    }
    .nav-logo {
        width: 60px;
        a {
            img.logo {
                
            }
        }
    }
    .left-item {
        padding-left: 0;
    }

}
@media screen and (max-width: 575px) {
    .nav-logo {
        width: 60px;
        
    }

    .right-block .top-nav .right-item .notification-card {
        padding: 5px 10px;
        .noti-btn {
            font-size: 10px;
            i {
                font-size: 8px;
            }
        }
    }

}



.showthisbox{
    display: block;
}
.showiconclass{
    display: block;
}
.hideiconclass{
    display: none !important;
}


.home-list-single .ciw-head {
    position: relative;
}

.home-list-single .ciw-head .sc-bottom {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0;
    background: #fff;
    z-index: 55;
}

.home-list-single {
    position: relative;
    z-index: 0;
}

main.clearfix {}

.homepage .left-block {
    bottom: inherit;
}

.homepage .right-block {
    margin: 0;
}


@media screen and (max-width: 991px) {
    .ser-maixmo-sel ul li {
        width: 50%;
        padding: 5px;
    }

    .ser-maixmo-sel ul {
        flex-wrap: wrap;
    }

    .popup-inner-smax {
        margin: 0 auto;
    }

    .ser-maixmo-sel ul li:nth-child(3) {
        width: 100%;
    }

    .ser-maixmo-sel ul li + li:nth-last-child(1) .single-fld:nth-last-child(2) {
        width: 50%;
        padding-right: 5px;
    }

    .ser-maixmo-sel ul li + li:nth-last-child(1) .single-fld:nth-last-child(1) {
        width: 50%;
        padding-left: 5px;
    }

    .single-fld button {
        margin: 0;
    }
}


@media screen and (max-width: 767px) {

    .ser-maixmo-sel ul li { width: 100%;}
    .ser-maixmo-sel ul li + li:nth-last-child(1) { flex-wrap: wrap;}
    .ser-maixmo-sel ul li + li:nth-last-child(1) .single-fld:nth-last-child(2),
    .ser-maixmo-sel ul li + li:nth-last-child(1) .single-fld:nth-last-child(1) { width: 100%; padding: 0; }
    .ser-maixmo-sel ul li + li:nth-last-child(1) .single-fld:nth-last-child(2) {padding-bottom: 10px}
    .ser-maixmo-sel {
        padding: 15px;
    }
    .lr-page .lr-wrapper2 .row {height: auto;  }
}
@media screen and (max-width: 575px) {
    .ser-maixmo-sel select, .ser-maixmo-sel input {
        padding: 12px 5px;
    }
}