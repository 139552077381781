@font-face {
    font-family: Montserrat;
    src: url("../fonts/web-font/Montserrat-Medium.eot");
    src: url("../fonts/web-font/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
         url("../fonts/web-font/Montserrat-Medium.woff") format("woff"),
         url("../fonts/web-font/Montserrat-Medium.ttf") format("truetype"),
         url("../fonts/web-font/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url("../fonts/web-font/Montserrat-SemiBold.eot");
    src: url("../fonts/web-font/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
         url("../fonts/web-font/Montserrat-SemiBold.woff") format("woff"),
         url("../fonts/web-font/Montserrat-SemiBold.ttf") format("truetype"),
         url("../fonts/web-font/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Montserrat;
    src: url("../fonts/web-font/Montserrat-Bold.eot");
    src: url("../fonts/web-font/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
         url("../fonts/web-font/Montserrat-Bold.woff") format("woff"),
         url("../fonts/web-font/Montserrat-Bold.ttf") format("truetype"),
         url("../fonts/web-font/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
body {font-weight: 500;}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  letter-spacing: -0.3px;
}
@media screen and (min-width: 768px) {
  .left-block .navlink ul li a:hover {
    color: rgb(255, 255, 255);
    background: rgb(56 57 130);
  }

  .left-block .navlink ul li li a:hover, 
  .left-block .navlink ul li li a.active {color: rgb(0 0 0);background: rgb(218 218 218);}

  .left-block .navlink ul li a:hover i,
  .left-block .navlink ul li a.active i {
    color: rgb(255, 255, 255);
  }
  
  .left-block .logo-icon 
{    display: none;
  }


  .login-register .input-field .all-inputs .lr-links p a:hover {
    text-decoration: underline !important;
  }

}
.left-block .navlink ul li a.active,
.left-block .navlink ul li a[aria-expanded="true"] {
  color: rgb(255, 255, 255);
  background: rgb(47 48 113);
}
#loader-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  margin: auto;
  background-position: center center;
  background-size: 400px;
  z-index: 99999;
}
#loader .svg-wrapper img {
  width: 100px;
}
#loader .svg-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.clearfix::after {
  visibility: hidden;
  display: block;
  font-size: 0px;
  content: " ";
  clear: both;
  height: 0px;
}
* a {
  text-decoration: none !important;
}
* {
  box-sizing: border-box;
  outline: none !important;
}
body {
  background: #f3f3f3;
  font-family: Montserrat, sans-serif;
  height: 100%;
  font-size: 14px;
}
input:focus,
textarea:focus,
select:focus {
  border-color: rgb(0, 0, 0) !important;
}
.left-block {
  background: rgb(255, 255, 255);
  width: 220px;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 999;
}
.left-block .nav-logo {
  padding: 37px 5px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  background: rgb(255, 255, 255);
  text-align: center;
  height: 75px;
}
.left-block .nav-logo a {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.left-block .nav-logo img.logo {
  width: 190px;max-width: inherit;
}
.left-block .nav-logo img.logo-icon {
  width: 50px;
}
.left-block .navlink {
  height: 100%;
  position: relative;
  /*padding-top: 77px;*/
}
.left-block .navlink > ul {
  margin: 0px;
  padding: 0px;
  height: 100%;
  overflow: auto;
}
.left-block .navlink ul > li {
  list-style: none;
  padding: 0px;
}
nav {
  position: relative;
  padding-top: 75px;
  height: 100%;
}
.left-block-body {
  height: 100%;
}
.left-block .navlink ul > li a {
  color: #5d5d5d;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 20px;
  font-size: 13px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  font-weight: 500;
  border-radius: 0px;
  position: relative;
}
.left-block .navlink ul > li a i.fas.fa-chevron-right {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  z-index: 1;
}
.left-block .navlink > ul li a i {
  font-size: 16px;
  width: 27px;
}
.close-menu {
  display: none;
  background: transparent;
  border: none;
  font-size: 25px;
  position: absolute;
  top: 14px;
  right: -40px;
  z-index: 55;
  color: rgb(255, 255, 255);
}
.Navoverlay {
  position: absolute;
  inset: 0px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 60;
  display: none;
}
.left-block .navlink .accordion-box {
  margin: 0px;
  padding: 0px;
}
.left-block .navlink .accordion-box a {
  padding: 13px 10px 12px 40px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right-block {
  background: rgb(255, 255, 255);
  position: relative;
}
.right-block,
.right-block .top-nav {
  margin-left: 220px;
}
.right-block .top-nav {
  background: rgb(47 48 113);
  padding: 0 15px;
  position: fixed;
  right: 0px;
  left: 0px;
  top: 0px;
  z-index: 55;
  height: 75px;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
  /* overflow: hidden; */
}
.nav-item .toggle-btn {
  color: rgb(249, 249, 249);
  background: transparent;
  border: none;
  padding: 0px;
  width: 35px;
  bottom: 0;
  font-size: 25px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}
.right-block .top-nav .right-item {
  display: inline-flex;
  padding-right: 25px;
  align-items: center;
}
.right-block .top-nav .title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 0px;
}
.right-block .top-nav .right-item .notification-card {
  position: relative;
  display: inline-block;
  background: #383880;
  padding: 8px 15px;
  margin-top: -1px;
  line-height: 1.14;
  border-radius: 4px;
  cursor: pointer;
}
.right-block .top-nav .right-item .setting, 
.right-block .top-nav .right-item .notification-card .noti-btn {
    color: rgb(255, 255, 255);
    background: transparent;
    border: 0px;
    font-size: 12px;
    position: relative;
    padding: 0px;
    display: inline-block;
    opacity: .7;
    transition: all 300ms;
}
.right-block .top-nav .right-item .setting, .right-block .top-nav .right-item .notification-card .noti-btn i {
    font-size: 12px;
    margin: 0 5px;
}
.right-block .top-nav .right-item .notification-card .noti-btn:hover { opacity: 1 }
.notification-card.show-noty .noty-body {
  visibility: visible !important;
  opacity: 1 !important;
}
.right-block .top-nav .right-item .notification-card span.point {
  background: rgb(238, 49, 84);
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 7px;
  right: 7px;
}
.right-block .top-nav .right-item .notification-card .noty-body {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 1;
    width: 170px;
    visibility: visible;
    opacity: 1;
}
.right-block .top-nav .right-item .notification-card .noty-body ul {
  margin: 0px;
  padding: 0px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 15px;
  background: rgb(255, 255, 255);
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
}
.right-block .top-nav .right-item .notification-card .noty-body ul li {
  border-bottom: 1px solid rgb(239, 239, 239);
  padding: 10px 15px;
  background: rgb(255, 255, 255);
  text-align: left !important;
}
.right-block
  .top-nav
  .right-item
  .notification-card
  .noty-body
  ul
  li:last-child {
  border-bottom: 0px;
  margin: 0px;
}
.right-block
  .top-nav
  .right-item
  .notification-card
  .noty-body
  ul
  li
  .img-name
  .img {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  float: left;
  background-color: rgb(193, 193, 193);
}
.right-block
  .top-nav
  .right-item
  .notification-card
  .noty-body
  ul
  li
  .img-name
  .img
  img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  max-width: inherit;
  height: 100%;
  object-fit: cover;
}
.right-block
  .top-nav
  .right-item
  .notification-card
  .noty-body
  ul
  li
  .img-name
  .txt {
 /* margin-left: 45px;*/
  display: flex;
  align-items: center;
  min-height: 30px;
  padding-top: 6px;

}
.right-block .top-nav .right-item .notification-card .noty-body ul li .img-name .txt h2 {
    font-size: 14px;
    color: rgb(0, 0, 0);
    /* font-weight: 600; */
    margin: 0px 0px 5px;
    white-space: nowrap;
    text-align: right;
}
.acs-input > input[readonly="true"], 
.acs-input > select[readonly="true"] { background: #f5f5f5;cursor: not-allowed;}
.right-block
  .top-nav
  .right-item
  .notification-card
  .noty-body
  ul
  li
  .img-name
  .txt
  span {
  display: block;
  font-size: 12px;
  color: rgb(165, 165, 165);
}
.right-block .top-nav .right-item .notification-card .noty-body ul li p {
  font-size: 12px;
  color: rgb(0, 0, 0);
  margin: 0px;
}
.right-block .top-nav .right-item .user-profile {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.right-block .top-nav .right-item .user-profile .noti-btn {
    color: rgb(255, 255, 255);
    background: transparent;
    border: 0px;
    font-size: 12px;
    text-align: center;
    position: relative;
    padding: 0px;
    display: inline-block;
    opacity: .7;
    transition: all 300ms;
}
.right-block .top-nav .right-item .user-profile a {
    width: 30px;
    height: 30px;
    overflow: hidden;
    display: block;
    position: relative;
    border-radius: 50%;
    border: 2px solid rgb(118 118 167);
    background: #383880;
}
.right-block .top-nav .right-item .user-profile a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.right-block .main-body {
  background: rgb(241, 241, 241);
  padding: 90px 15px 15px;
  min-height: 100vh;
}
.right-block .inner-body {
  background: rgb(255, 255, 255);
  padding: 20px;
  min-height: calc(100vh - 105px);
}
/**********************/
.lr-page {
  background: rgb(243, 243, 243);
  padding: 40px 0px;
  min-height: 100vh;
}
.lr-page .lr-wrapper1 {
  display: table;
  min-height: calc(100vh - 173px);
  width: 100%;
}
.lr-page .lr-wrapper2 {
  display: table-cell;
  vertical-align: middle;
}
.lr-page .img {
  text-align: center;
  height: 100%;
  position: relative;
}
.lr-page .img img {
  max-height: 180px;
  width: 100%;
}
.lr-page .lr-wrapper2 .row {
  align-items: center;
  height: calc(100vh - 40px);
}
.lr-page .lr-form {
  max-width: 400px;
  width: 100%;
  margin: 0px auto;
}
.lr-page .heading {
  margin: 0px 0px 30px;
  max-width: 100%;
}
.lr-page .heading h2 {
  font-size: 22px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  margin: 0px;
}
.lr-page .heading h2 strong {
  color: #3d67b1;
}
.lr-page .heading p {
  font-size: 14px;
  color: rgb(46, 46, 46);
  margin: 0px 0px 20px;
}
.lr-page .form {
  background: rgb(255, 255, 255);
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 15px 1px;
}
.lr-page .form .form-group {
  margin-bottom: 15px;
}
.lr-page .form .form-group label {
  display: block;
  margin: 0;
  font-size: 13px;
  color: #9c9c9c;
  font-weight: 500;
}
.lr-page .form .form-group input,
.lr-page .form .form-group select {
  width: 100%;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgb(227, 227, 227);
  height: 25px;
  padding: 0;
  font-size: 14px;
  color: rgb(0, 0, 0);
}
.lr-page .link {
  text-align: center;
  margin: 20px 0 0;
}
.lr-page .link p {
  font-size: 15px;
  color: rgb(115, 115, 115);
  font-weight: 500;
  margin: 0px;
}
.lr-page .link p a {
  color: #3d67b1;
}
.forgot-link {
  margin-bottom: 15px;
}
.lr-page .forgot-link a {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #3d67b1;
}

.lr-page .forgot-link a:hover {
  text-decoration: underline !important;
}
.lr-page .btns {
  margin: 20px 0px 0px;
}
.lr-page .btns a {
  width: 100%;
  display: block;
  text-align: center;
  padding: 12px 10px;
  transition: all 0.3s ease 0s;
  background: rgb(41 43 117);
  color: rgb(255, 255, 255);
  border-radius: 30px;
  cursor: pointer;
}
.lr-page .txt {
  text-align: center;
}
.lr-page .txt h2 {
  font-size: 20px;
  color: rgb(46, 46, 46);
  font-weight: 500;
  margin: 0px 0px 5px;
}
.lr-page .txt p {
  font-size: 14px;
  color: rgb(46, 46, 46);
  margin: 0px 0px 20px;
}
.join-page .lr-form {
  max-width: 100%;
  margin: 30px auto;
}
.join-page .form {
  max-width: 400px;
  width: 100%;
  margin: 50px auto;
}
.signup .lr-form {
  max-width: 600px;
}
.signup .btns {
  margin: 30px 0px 0px;
}
.signup .btns .btn2 {
  float: left;
  border: 1px solid rgb(7, 114, 202);
}
.signup .btns .btn1 {
  float: right;
}
.signup .btns a {
  display: inline-block;
  padding: 10px 30px;
  width: auto !important;
}
.signup .check-list {
  max-height: 450px;
  overflow: auto;
}
.signup .heading h2 {
  margin: 0px 0px 5px;
}
.lr-page .sub-heading {
  margin: 0px 0px 15px;
}
.lr-page .sub-heading h2 {
  font-size: 15px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  margin: 0px;
}
.check-box input[type="checkbox"] {
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px 0 0;
}

.check-box input[type="checkbox"] + span {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
}

.check-box label {
    vertical-align: middle;
    margin: 0;
    cursor: pointer;
}
.navlinkTop {
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.navlinkTop h5 {
    font-size: 16px;
}

.navlinkTopSearch input[type="search"] {
    width: 100%;
    border: 1px solid #ddd;
    font-size: 12px;
    padding: 5px 10px;
}







.left-item > ul, .right-item > ul {
    padding: 0;
    margin: 0 -4px;
}
.right-item > ul {
    position: relative;
    padding-right: 65px;
    min-height: 30px;
    vertical-align: middle;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.right-item > ul > li:last-child {
    position: absolute;
    top: 50%;
    right: -6px;
    margin-top: -17px;
}
.left-item > ul > li, .right-item > ul > li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 8px;
    color: #bfbfbf;
}

.left-item > ul > li > a,
.right-item > ul > li > a {
    color: #ffffff;
    text-align: center;
    display: block;
    text-transform: uppercase;
    /* font-weight: 600; */
    opacity: .7;
    transition: all 300ms;
}

.left-item > ul > li > a span,
.right-item > ul > li > a span {
    display: block;
    font-size: 11px;
}

.left-item > ul > li .fa, .right-item > ul > li .fa {
    font-size: 14px;
}

.left-item > ul > li.active > a, .left-item > ul > li > a:hover,.left-item > ul > li > a:hover, .right-item > ul > li > a:hover {
    color: #fff;
    opacity: 1;
}
.tellus-wrap {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 0;
}

.tellus-wrap h4 {
    font-weight: 600;
    margin: 20px 0;
}

.tellus-wrap ul {
    padding: 0 0 0 40px;
    margin: 25px 0;
}

.tellus-wrap ul li {
    display: block;
    /*padding: 10px 20px 10px 35px;
    border: 1px solid #eee;*/
    position: relative;
    cursor: grab;
    background: #fff;
    z-index: 0;
}
.tellus-wrap ul li span {
    display: block;
    padding: 10px 20px 10px 35px;
    border: 1px solid #eee;
    position: relative;
    background: #fff;
    z-index: 0;
    transition: all 300ms;
  }

.tellus-wrap ul li span:hover {
    border-color: #e5e5e5;
    box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
}
.tellus-wrap ul li + li {
    margin-top: 5px;
}
.btm-button {
    text-align: right;
    padding-top: 20px;
}

.btm-button button, .btm-button a {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding: 12px 40px;
    transition: all 0.3s ease 0s;
    background: rgb(47 48 113);
    color: rgb(255, 255, 255);
    border-radius: 30px;
    cursor: pointer;
}
.btm-button button:hover, .btm-button a:hover {
    background: rgb(68 69 144);
    color: #fff;
}
.tellus-wrap ul li span:after {content: "\f338";font-family: "Font Awesome 5 Free";font-weight: 900;position: absolute;top: 9px;left: 15px;}

.ciw-single {
    /*padding: 25px 0;*/
    border: 1px solid #eee;
    box-shadow: 0 3px 10px rgb(0 0 0 / 5%);
    height: 100%;
}

.ciw-single h2 {
    font-size: 17px;
    font-weight: 600;
    /* text-transform: uppercase; */
    color: rgb(47 48 113);
}

.ciw-single > p {
    margin: 15px 0;
    font-size: 14px;
    font-weight: 600;
}

.radio-type-check ul {
    padding: 10px 0 5px;
    margin: 0;
}

.radio-type-check ul li {
    display: block;
    margin: 0;
}

.sc-top {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    align-items: flex-end;
}

.sc-left {
    width: 80%;
}

.sc-right {
    width: 20%;
    position: relative;
    margin: 10px 0;
    padding-right: 4%;
}

.progress {
    height: 6px;
    border-radius: 20px;
    background-color: #eaeaea;
}

.sc-right i {
    position: absolute;
    top: -8px;
    right: 0;
    color: rgb(47 48 113);
    font-size: 22px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
}

.sc-left label {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}

.sc-left label input[type="checkbox"],
.sc-left label input[type="radio"],
.ciw-head .sc-right label input[type="checkbox"],
.right-progress label input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
}

.sc-left label input[type="checkbox"] ~ span i,
.sc-left label input[type="radio"] ~ span i,
.ciw-head .sc-right label input[type="checkbox"] ~ span i,
.right-progress label input[type="checkbox"] ~ span i {
    position: absolute;
    top: 1px;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #a0a0a0;
    border-radius: 50%;
}

.sc-left label input[type="checkbox"] ~ span,
.sc-left label input[type="radio"] ~ span,
.ciw-head .sc-right label input[type="checkbox"] ~ span,
.right-progress label input[type="checkbox"] ~ span {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.sc-left label input[type="checkbox"]:checked ~ span i,
.sc-left label input[type="radio"]:checked ~ span i,
.ciw-head .sc-right label input[type="checkbox"]:checked ~ span i,
.right-progress label input[type="checkbox"]:checked ~ span i {
    background: #2f3071;
}

.sc-left label input[type="checkbox"] ~ span i:after,
.sc-left label input[type="radio"] ~ span i:after,
.ciw-head .sc-right label input[type="checkbox"] ~ span i:after,
.right-progress label input[type="checkbox"] ~ span i:after {font-family: "Font Awesome 5 Free";font-weight: 900;position: absolute;top: 0;left: 0;right: 0;bottom: 0;font-size: 12px;line-height: 17px;text-align: center;display: block;color: #969696;padding-right: 2px;}
.sc-left label input[type="checkbox"] ~ span i:after,
.sc-left label input[type="radio"] ~ span i:after,
.ciw-head .sc-right label input[type="checkbox"] ~ span i:after,
.right-progress label input[type="checkbox"] ~ span i:after {content: '\f00d';}

.sc-left label input[type="checkbox"]:checked ~ span i:after,
.sc-left label input[type="checkbox"]:checked ~ span i:after,
.ciw-head .sc-right label input[type="checkbox"]:checked ~ span i:after,
.right-progress label input[type="checkbox"]:checked ~ span i:after {
    content: '\f00c';
    color: #fff;
}

.sc-left label input[type="checkbox"]:checked ~ span i,
.sc-left label input[type="checkbox"]:checked ~ span i,
.ciw-head .sc-right label input[type="checkbox"]:checked ~ span i,
.right-progress label input[type="checkbox"]:checked ~ span i {border-color: #2f3071;}

/*.radio-type-check ul li + li {
    margin-top: 12px;
}*/

.single-induistl-list {
    position: relative;
    padding: 0 100px 0 70px;
}

.left-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border: 2px solid #ffc107;
    border-radius: 50%;
    overflow: hidden;
}

.left-img img {
    max-width: 100%;
}

.induistl-list ul {
    padding: 10px 0 5px;
    margin: 0;
}

.induistl-list ul li {
    display: block;
}

.right-uplist {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.right-content p {margin: 5px 0;font-size: 16px;}

.right-content p.small-text {
    font-size: 13px;
}

.orange-text {
    color: #ffc107;
}

.induistl-list ul li + li {
    margin-top: 25px;
}
.ciw-single + .ciw-single {
    margin-top: 20px;
}
.right-content p a {
    color: #ffc107;
    vertical-align: middle;
}
.right-content p a img {
    vertical-align: middle;
    margin: -4px 3px 0 5px;
    max-width: 20px;
}
.red-text {
    color: #af0000;
}

.red-text i {
    font-size: 18px;
    vertical-align: text-bottom;
}

.ciw-head {
    padding: 0 30px;
}

.radio-type-check {
    padding: 0 30px;
}

.sc-bottom {
    padding: 17px 28px;
    margin: 0 -30px;
    box-shadow: inset 0 0 10px #00000038;
    display: none;
}

.sc-bottom > p {
    margin: 0;
    position: relative;
    padding: 5px 0 3px;
    padding-left: 40px;
}

.sc-bottom p i {width: 25px;height: 26px;text-align: center;color: #000;border: 2px solid #000;line-height: 24px;border-radius: 50%;position: absolute;top: 50%;left: 0;transform: translateY(-50%);}

.sc-bottom ul {
    padding: 20px 0 0;
    padding-left: 40px;
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
}

.sc-bottom ul li {
    width: 50%;
    padding: 5px 10px;
    display: block;
}

.perf-right {
   
    width: 150px;
    padding-left: 90px;
    margin: 2px 0;
}
.sb-single p + .perf-right {
   position: absolute;
    top: 0;
    right: 0;
}

.sb-single {
    position: relative;
    padding-right: 150px;
}

.perf-right p {
    width: 80px;
    
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    line-height: 20px;
    font-size: 14px;
    margin: 0;
}
.sb-single p + .perf-right p {
  text-align: right;
}

.right-progress .progress {
    height: 20px;
    border-radius: 4px;
    border: 2px solid #464646;
    background: #464646;
}

.sc-bottom ul li + li {
    margin: 0;
}

.sb-single > p {
    margin: 0;
}

.sb-single > p a {
    display: inline-block;
    vertical-align: middle;
    color: #ffc107;
}

.sb-single > p a img {
    margin-top: -2px;
}
.induistl-list {
    padding: 0 30px;
}
.open .sc-right i:before {
    content: "\f0d8";
}
.with-onoff-btn {
    position: relative;
    padding-right: 100px;
}

.with-onoff-btn .minor-imp {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
}
.minor-imp label {
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
}
.minor-imp label input[type="checkbox"] { position: absolute; opacity: 0; visibility: hidden; }
.minor-imp label input[type="checkbox"] ~ span {
    display: inline-block;
    vertical-align: middle;
    width: 45px;
    height: 20px;
    background: #4d4d4d;
    position: relative;
    cursor: pointer;
    border-radius: 40px;
    overflow: hidden;
}

.minor-imp label input[type="checkbox"] ~ span > i {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 40px;
}
.minor-imp label input[type="checkbox"]:checked ~ span {background: #ffc107;}
.minor-imp label input[type="checkbox"]:checked ~ span > i {left: 27px;}
.minor-imp label input[type="checkbox"] ~ span,
.minor-imp label input[type="checkbox"] ~ span > i,
.sc-left label input[type="checkbox"] ~ span i,
.sc-left label input[type="radio"] ~ span i,
.left-item > ul > li > a,
.rti-head-top .rth-right ul li a { transition: all 300ms; }



.rth-left h4 {
    margin: 0 15px 0 0;
    font-size: 16px;
    font-weight: normal;
}

.rth-left h4 i {
    font-size: 16px;
    margin: 0 10px;
}

.rti-head-top .rth-left {
    display: flex;
    flex-wrap: wrap;
    color: #777;
}

.rti-head-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -10px;
    padding: 10px 30px 10px 0;
}

.rti-head-top .rth-left,.rti-head-top .rth-right {
    width: 50%;
    padding: 0 10px;
}

.rti-head-top .rth-right ul {
    padding: 0;
    margin: 0;
    text-align: right;
}

.rti-head-top .rth-right ul li {
    display: inline-block;
    vertical-align: middle;
}

.rti-head-top .rth-right ul li a {
    color: #777;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
}

.rti-head-top .rth-left select {
    border: 0;
    font-size: 12px;
    padding: 0;
    vertical-align: middle;
    color: #555;
    cursor: pointer;
}

.rthb-left h1 {
    font-size: 14px;
}

.rthb-left h1 span {
    font-size: 30px;
    font-weight: 600;
}

.rti-head-bottom {
    padding-top: 5px;
}

.rthb-left {
    padding-bottom: 30px;
}
.rt-inner {
    position: relative;
    border: 1px solid #eee;
    padding-left: 15px;
    overflow: hidden;
}



.report-top.close-right-pan .fa-chevron-right:before {
    content: "\f053";
}
.rt-inner .rti-right {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    width: 30%;
}

.rti-left {
    border-right: 1px solid #eee;
}

.tab-content ul {
    padding: 5px 15px 10px;
    margin: 0;
}

.tab-content ul li {
    display: block;
    padding: 3px 0;
}

.tab-content ul li label {
    display: block;
    margin: 0;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.tab-content ul li label input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
}

.tab-content ul li label input[type="checkbox"] ~ span {
    position: absolute;
    top: 1px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 3px;
}

.tab-content ul li label input[type="checkbox"] ~ p {
    margin: 0;
    line-height: 1.45;
    font-size: 12px;
}

.tab-content {
    padding: 15px;
}

.tab-content .tab-pane {
    border: 1px solid #eee;
}

.top-search label {
    margin: 0;
    display: block;
    position: relative;
}

.top-search label input[type="search"] {
    width: 100%;
    border: 0;
    padding: 10px 15px;
    font-size: 13px;
    background: #fff;
}

.top-search label i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 14px;
    color: #777;
}

.top-search {
    padding-right: 40px;
    position: relative;
}

.top-search > i {
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 16px;
    transform: translateY(-50%);
    color: #777;
}

.tab-content ul li label input[type="checkbox"] ~ span > i:before {content: '\f00c';}

.tab-content ul li label input[type="checkbox"] ~ span > i {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 10px;
    line-height: 15px;
    color: #fff;
    text-align: center;
    padding-right: 3px;
}

.tab-content ul li label input[type="checkbox"]:checked ~ span {
    background: #89c7f9;
    border-color: #89c7f9;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: 0;
    border-bottom: 3px solid #89c7f9;
    color: #000;
}

.nav-tabs .nav-link {font-size: 10px;text-transform: uppercase;font-weight: 600;letter-spacing: .3px;padding: 10px 0;color: #bbb;border: 0;border-bottom: 3px solid transparent;}

.nav-tabs {padding: 0 5px;}

.nav-tabs .nav-item {
    padding: 0 10px;
}
.link-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.link-search h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
}

.search-box label {
    margin: 0;
    display: block;
    position: relative;
}

.search-box label input[type="search"] {
    width: 100%;
    border: 1px solid #ddd;
    padding: 5px 30px;
    font-size: 12px;
}

.search-box label input[type="search"] ~ i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.search-box label input[type="search"] ~ i.fa-search {
    left: 8px;
    font-size: 12px;
    color: #777;
}

.search-box label input[type="search"] ~ i.fa-angle-down {
    right: 10px;
    font-size: 14px;
    color: #888;
}

.link-rela-carts ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2px;
}

.link-rela-carts ul li {
    display: block;
    width: 25%;
    padding: 2px;
}

.lrc-single {
    padding: 10px;
    border: 1px solid #e5e5e5;
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 1px 3px #00000014;
}

.lrc-left {width: 30%;text-align: center;}
.lrc-right {width: 70%;}
.big-graph { min-height: 280px; }
.lrc-right h3 {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
    position: relative;
}

.lrc-right h3 i {
    position: absolute;
    top: 0;
    right: 0;
    color: #777;
}

.lrc-right p {
    font-size: 9px;
    color: #999;
    margin: 0;
}

.lrc-right p span {font-size: 16px;padding-right: 5px;}

.link-rela-carts {
    padding: 10px 0;
}

.rb-single {
    padding: 12px 0;
}
.mximo-head {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 15px 12px;
    /*box-shadow: 0 0px 4px #0000003d;*/
        border: 1px solid #eee;
    margin-bottom: 20px;
}

img { max-width: 100%; }
.blue-text {
    color: #4e7d9a;
}

.maximo-text span {
}

.maximo-text span i {
    margin: 0 5px;
    color: #777;
}

.maximo-text {
    font-size: 15px;
    margin: 0;
}

.maximo-text span + span:before {content: '';display: inline-block;width: 5px;height: 5px;background: #ccc;margin: 0 7px;vertical-align: middle;border-radius: 50%;}

.mximo-head ul {padding: 0 0 0 5px;margin: 0;}

.mximo-head ul li {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    margin: 3px 0;
    color: #999;
}

.mximo-head ul li:before {content: '';display: inline-block;width: 5px;height: 5px;background: #ccc;margin: 0 6px;vertical-align: middle;border-radius: 50%;}

.mximo-head ul li.blue-text {
    color: #4e7d9a;
}

.sinlge-cart {
    width: 25%;
}

.mximo-carts {
    display: flex;
    flex-wrap: wrap;
}

.admin-cart-top:after,.admin-cart-top:before {content: '';display: table;clear: both;}

.admin-img {
    width: 50px;
    height: 50px;
    background: #eee;
    border-radius: 50%;
    overflow: hidden;
    float: left;
}

.admin-text {
    margin-left: 65px;
    padding: 7px 0 0;
}

.admin-text > span {
    display: block;
    font-size: 12px;
    color: #bbb;
}

.admin-text p {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
}

.admin-cart-bottom {
    margin-top: 12px;
    font-size: 14px;
}

.check-box p {
    margin: 0;
    position: relative;
    padding-left: 30px;
    color: #777;
    max-width: 240px;
}

.check-box p > span {
    position: absolute;
    top: 1px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #89c7f9;
    border-radius: 3px;
    background: #89c7f9;
}

.check-box p > span > i {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 19px;
    color: #fff;
    text-align: center;
    padding-right: 3px;
}

.check-box p > span > i:after {
    content: '\f00c';
}

.check-box p > i {
    color: #999;
    margin-right: 3px;
}

.sinlge-cart > ul {
    padding: 0;
    margin: 0;
}

.sinlge-cart > ul > li {
    display: inline-block;
    vertical-align: middle;
    margin: 3px 6px 3px 0;
}

.sinlge-cart > ul > li img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
}

.sinlge-cart > p {
    font-size: 13px;
}

.sinlge-cart > ul > li span {
    display: block;
    font-size: 10px;
    border: 1px solid #eee;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
}
.check-box {
    padding-left: 5px;
}

.toggle-pan {
    position: absolute;
    top: 14px;
    left: -30px;
    width: 30px;
    height: 24px;
    border: 1px solid #eee;
    text-align: center;
    background: #fff;
    box-shadow: -2px 0 3px #00000026;
    font-size: 12px;
    line-height: 22px;
    cursor: pointer;
}
.main-pop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    z-index: 999;
}
.main-pop, .rt-inner,
.rt-inner .rti-right {transition: all 300ms;}
main:not(.show-pop) .main-pop {visibility: hidden;opacity: 0}

.main-pop-inner {max-width: 1050px;margin: 0 auto;background: #ebebeb;position: relative;z-index: 1;height: 100%;box-shadow: 0 0 10px #000;border-radius: 4px;overflow: hidden;padding-top: 60px;}

.main-pop .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000075;
    z-index: 0;
}

/*.pop-head {
    background: rgb(137, 199, 249);
    padding: 16px 25px;
    color: #fff;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 1;
}*/

.pop-head-input input {
    width: 100%;
    max-width: 300px;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #fff;
    color: #fff;
    outline: none !important;
}

.close-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

.pop-head-input {
    padding-right: 50px;
}

.pop-content-inner {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.pop-left {
    width: 30%;
    padding: 20px;
}

.pop-right {
    width: 70%;
    padding-bottom: 70px;
    background: #fff;
    box-shadow: 0 0 4px #0000002e;
    height: 100%;
    position: relative;
}
.pop-right-inner {padding: 30px;max-height: 100%;overflow: auto;}
.pop-left ul {
    margin: 0;
    padding: 0;
}

.pop-left ul li {
    display: block;
    padding: 10px 15px;
    background: #f2f2f2;
    border: 1px solid #e0e0e0;
}

/*p {margin: 0;color: #bbb;}*/

.pop-left ul li p span {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    display: inline-block;
    border: 2px solid #ccc;
    line-height: 26px;
    text-align: center;
    font-size: 14px;
    border-radius: 50%;
    margin-right: 9px;
}

.pop-left ul li + li {
    margin-top: 15px;
}

.pop-right h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 20px;
}

.pop-right p.personliz-data {color: #777;font-size: 14px;font-weight: 600;margin: 0 0 10px;}

.pop-right p.personliz-data span {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border: 1px solid #ccc;
    text-align: center;
    border-radius: 50%;
    color: #bbb;
    font-size: 10px;
    margin-left: 5px;
}

.pop-right p {
    font-size: 14px;
    margin: 0 0 10px;
    color: #000;
}

.single-input label {
    margin: 0 0 5px;
    display: block;
    font-size: 12px;
    color: #333;
}

.single-input select,.single-input input {
    width: 100%;
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.single-input select {padding-left: 30px;background: #fff url(../images/search_icon.svg) no-repeat 8px center;background-size: 18px auto;}
.single-input  p {
    font-size: 11px;
    margin: 5px 0;
    color: #a0a0a0;
}

.double-field {
    margin: 20px 0;
}

.double-field {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -10px 0;
}

.double-field .single-input {
    width: 50%;
    padding: 0 10px;
}

.single-input select option {
    font-size: 13px;
}

/*.pop-foot {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    border-top: 1px solid #ddd;
    padding: 13px 30px;
    height: 70px;
}*/
.pop-content {
    height: 100%;
}

.pop-foot ul {
    padding: 10px;
    margin: 0;
    text-align: center;
    border-top: 1px solid #eee;
}

.pop-foot ul li {
    display: block;
    letter-spacing: .5px;
}
.pop-foot ul.dbl-li {
    display: flex;
}

.pop-foot ul.dbl-li li {
    width: 50%;
    padding: 0 8px;
}
.pop-foot ul li a, .pop-foot ul li button {
    padding: 10px 30px;
    display: block;
    border: 0;
    border-radius: 4px;
    width: 100%
}

.normal-btn a,.normal-btn button {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: #555;
    background: #fff;
}

.fill-btn a, .fill-btn button {
    vertical-align: middle;
    text-align: center;
    transition: all 0.3s ease 0s;
    background: rgb(255, 193, 7);
    color: rgb(255, 255, 255);
    border-radius: 30px;
}

.pop-left ul li.active span {
    border-color: #89c7f9;
    color: #89c7f9;
}

.pop-left ul li.active p {
    color: #333;
}


.pop-head-input ::placeholder { 
  color: #fff;
  opacity: 1;
}

.pop-head-input :-ms-input-placeholder { 
  color: #fff;
}

.pop-head-input ::-ms-input-placeholder {
  color: #fff;
}
.nav-item {
  position: relative;
  height: 100%;
}
.nav-item > .row {
    height: 100%;
    align-items: center;
}
.nav-item .toggle-btn:after {content: '';position: absolute;top: 0;right: -10px;bottom: 0;border-right: 1px solid #4f5092;}
.pop-left ul li p { margin: 0;}
.left-block a[aria-expanded="true"] .fa-chevron-right:before { content: "\f078"; }
.rti-head-top .rth-right ul li a:hover {color: #89c7f9;}

.use-fail .sc-left { width: 100%; }


.ciw-head .sc-right {
    margin: 0;
}
.ciw-head .sc-right:not(.with-progress) { padding-right: 0;}
.sc-right:not(.with-progress) .scr-inner {
    position: relative;
}

.sc-right:not(.with-progress) .scr-inner i {
    top: 3px;
}

.scr-inner label {display: inline-block;vertical-align: middle;margin: 0;height: 22px;width: 20px;}
.ciw-head p {
    margin: 0;
    
}
.sc-top p {color: #999; }
.sc-top .sc-left h3 {
    font-size: 16px;
    margin: 0;
}

.sc-top + .sc-top {
    border-top: 1px solid #eee;
    align-items: center;
}







/*------------------*/

.table-head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.th-left {
    width: 40%;
}

.th-left h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    color: #2f3071;
}

.th-right {
    text-align: right;
    width: 60%;
}

.th-right a {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 20px;
    width: 180px;
    max-width: 100%;
    text-align: center;
    background: #2f3071;
    color: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 6px #2f307170;
    transition: all 300ms;
}
.th-right a:hover {background: #3d67b1;}
.table-wrap table thead tr th,
.table-wrap table tbody tr td:last-child { white-space: nowrap; }

.table-wrap a {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
    color: #3d67b1;
}

.table-wrap a:hover {
    color: #2f3071;
}
.img-name {
    cursor: pointer;
}
.ac-head ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.ac-head ul li {
    display: block;
    margin-right: -1px;
}

.ac-head ul li span {
    display: block;
    padding: 7px 20px;
    border: 2px solid #ddd;
    font-weight: 600;
    color: #bbb;
    letter-spacing: .5px;
    user-select: none;
}

.ac-head ul li.active span {
    position: relative;
    z-index: 1;
    border-color: #2f3071;
    color: #2f3071;
}

.ac-head {
    margin-bottom: -2px;
}

.ac-body {
    border: 1px solid #ddd;
    padding: 20px;
}

.ac-single {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.acs-head {
    width: 200px;
}

.acs-input > input, .acs-input > select {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    outline: none;
    transition: all 300ms;
    width: 500px;
    max-width: 100%;
}

.ac-body ul {
    padding: 0;
    margin: 0;
}

.ac-body > ul  > li {
    margin: 0;
    display: block;
    padding: 8px 0;
}

.acs-input input:focus,
.acs-input > select:focus {
    border-color: #2f3071;
}

.acs-input > input:hover,
.acs-input > select:hover {
    border-color: #a0a0a0;
}
.ac-btn {
    text-align: right;
    border-top: 1px solid #ddd;
    margin: 20px -20px 0;
    padding: 15px 20px 0;
}

.ac-btn button {
    padding: 10px 20px;
    border: 0;
    background: #2f3071;
    color: #fff;
    width: 150px;
    border-radius: 5px;
    box-shadow: 0 2px 6px #2f307170;
    transition: all 300ms;
    margin: 5px 10px;
}
.ac-btn button.back-btn {
    background: #eee;
    color: #555;
    font-weight: 600;
    box-shadow: 0 2px 6px #0000002b;
}
.ac-head ul li.done span {
    background: #ddd;
    color: #666;
}
.radio-buttons ul li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    padding: 5px 0;
}

.single-radio label {
    display: block;
    margin: 0;
    cursor: pointer;
    position: relative;
}
.single-radio label input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
}

.single-radio label input[type="radio"] ~ span {
    display: block;
    padding-left: 25px;
}

.single-radio label input[type="radio"] ~ span > i {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 50px;
    transition: all 300ms;
}

.single-radio label input[type="radio"] ~ span > i:after {content: '';position: absolute;top: 4px;left: 4px;right: 4px;bottom: 4px;/* background: #ccc; */border-radius: 50px;transition: all 300ms;}

.single-radio label input[type="radio"]:checked ~ span > i:after {
    background: #2f3071;
}

.single-radio label input[type="radio"]:checked ~ span > i {
    border-color: #2f3071;
}
.acs-head > span {
    display: block;
    font-size: 11px;
    font-style: italic;
}

.left-item > ul > li.parent {
    position: relative;
}

.left-item > ul > li.parent > ul {
    position: absolute;
    background: #2f3071;
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 6px rgb(0 0 0 / 40%);
    border-radius: 3px;
    min-width: 100px;
    display: none;
}

.left-item > ul > li.parent > ul > li {
    display: block;
}

.left-item > ul > li.parent > ul > li a {
    display: block;
    padding: 10px 16px;
    font-size: 12px;
    color: rgb(255 255 255 / 70%);
    white-space: nowrap;
}

.left-item > ul > li.parent > ul > li + li {
    border-top: 1px solid #ffffff26;
}

.left-item > ul > li.parent > ul > li a:hover {
    color: #fff;
}

.left-item > ul > li.parent:hover > ul {
    display: block;
}
.right-item .logout-btn a {
    font-size: 20px;
    display: block;
    cursor: pointer;
}
.popup-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    padding: 20px;
}

.popup-wrap .overlay {
    background: rgb(0 0 0 / 80%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.popup-inner {
    max-width: 430px;
    margin: 0 auto;
    position: relative;
    background: #fff;
    text-align: center;

}

.popup-inner-smax {
    max-width: 1100px;
    margin: 65px auto;
    position: relative;
    background: #fff;
    text-align: center;
    border-radius: 12px;
}
/*.popup-wrap .popup-inner {top: -100%;}*/
.pop-icon {
    /* width: 90px; */
    /* height: 90px; */
    text-align: center;
    background: #090a17;
    /* border-radius: 50%; */
    margin: 0 auto;
}

.pop-icon p {
    font-size: 30px;
    display: block;
    line-height: 90px;
    color: #ffffff;
}

.pop-head p {
    margin: 20px 0;
    font-size: 17px;
    letter-spacing: 0;
}
.pop-foot ul li.delete-btn button {
    background: #898cb1;
    color: #fff;
}
.pop-foot ul li.delete-btn .active {
    background: #2f3071;
    color: #fff;
}

.success-mess p, .error-mess p {
    margin: 0;
    font-size: 13px;
}

.success-mess,.error-mess {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    text-align: left;
}

.error-mess {
    color: #e00000;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.success-mess {
    
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.user-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-color: #eee;
}

.table-wrap table thead tr th {
    background: #2f3071;
    color: #fff;
    border-color: #48498a;
    padding: 8px 15px;
}

.table td, .table th {
    padding: 5px 15px;
    vertical-align: middle;
}






.circle {
    width: 180px;
    height: 180px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    transition: all .3s;
    margin: 0 auto;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-position: center center; 
    cursor: pointer;
    background-size: 80% auto;
}

.circle img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}

.profile-pic {
    display: block;
}
.choose-image input.file-upload {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.change-pic {
    position: absolute;
    top: 53%;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    z-index: 1;
    font-size: 18px;
    transform: translateY(-50%);
    opacity: 0;
}

.change-pic .fa {
    font-size: 30px;
}
.choose-image {
    width: 180px;
    margin: 0 auto;
}
.circle:hover .change-pic, .select-image label:after {
    opacity: 1;
    visibility: visible;
}
.circle:hover .change-pic {
    margin-top: 0;
}
.change-pic {
    position: absolute;
    top: 53%;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    z-index: 1;
    font-size: 12px;
    transform: translateY(-50%);
    opacity: 0;
    
}
.change-pic,.select-image label, .select-image label:after {transition: all 300ms;}
.select-image label:hover .change-pic,.select-image label:hover:after {
    opacity: 1;
    visibility: visible;
}

.select-image label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 40%);
    z-index: 0;
    opacity: 0;
    visibility: hidden;
}
.select-image label {
    display: block;
    position: relative;
    width: 120px;
    height: 120px;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
    background: #f5f5f5;
    cursor: pointer;
}
.select-image label input#file {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
}

.select-image label img.profile-pic {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}
label.status-btn {
    position: relative;
}

label.status-btn input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
}

label.status-btn input[type="checkbox"] ~ span {
    display: block;
    width: 23px;
    height: 5px;
    background: #e8bbbb;
    border-radius: 12px;
}

label.status-btn input[type="checkbox"] ~ span i {
    position: absolute;
    top: -5px;
    left: 0;
    width: 14px;
    height: 14px;
    background: #dc9c9c;
    border-radius: 50%;
}

label.status-btn input[type="checkbox"]:checked ~ span {
    background: #3a903d;
}

label.status-btn input[type="checkbox"]:checked ~ span i {
    left: 10px;
    background: #3a903d;
}

.no-location {
    background: #383880;
    font-size: 13px;
    padding: 10px 10px;
    border-radius: 4px;
}

.no-location p {
    font-size: 12px;
    position: relative;
    margin: 0;
    padding-right: 23px;
}

.no-location p span {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
}
.no-location p span:hover { color: #fff }