@media screen and (min-width: 1200px){	
	.report-top.close-right-pan .rti-right { transform: translateX(100%); }
	.report-top:not(.close-right-pan) .rt-inner {padding-right: 30%;}


}

@media screen and (max-width: 1199px){
	.rti-head-top .rth-left, .rti-head-top .rth-right { width: 100%;}
	.rti-head-top .rth-right {border-top: 1px solid #eee;border-bottom: 1px solid #eee;margin-top: 8px;padding-bottom: 3px;}
	.rti-head-top {padding-right: 0;}
	.rti-head-top .rth-left { padding-right: 40px;	}
	.report-top:not(.close-right-pan) .rti-right { transform: translateX(100%);}
	.rt-inner .rti-right { border-left: 1px solid #eee;box-shadow: 0 0 4px #00000038; min-width: 250px;}
	.rti-head-top { margin-left: -15px;}
}

@media screen and (min-width: 992px){
	.nav-item .toggle-btn { display: none; }
}

@media screen and (max-width: 991px) {
/*********nav***********/
	.nav-item { padding-left: 60px;}
	.right-block .top-nav .right-item{padding: 0;}
	.right-block .top-nav .right-item .notification{margin-right: 20px;	}
	.right-block, .right-block .top-nav { margin: 0;}
	.d-none-m{display: none;}
	.toggle-class .Navoverlay{display: block;}
	.toggle-class  .close-menu{display: block;}
	
	.toggle-class .left-block { transform: translate(0) !important;}
	.left-block { width: 220px;	}

	.left-block{transform: translate(-100%);transition: all 0.3s;}
	.left-block .nav-logo img.logo-icon { display: none;}

	.right-block .main-body{padding: 90px 15px 20px;}
	.link-rela-carts ul li {width: 50%;}
	.admin-cart.sinlge-cart { width: 100%; border-bottom: 1px solid #eee; padding-bottom: 15px;margin-bottom: 15px;}
	.sinlge-cart { width: 33.333%; padding: 5px;}
	.check-box p { max-width: inherit;}
	.left-block .navlink .accordion-box a {padding: 8px 10px 8px 35px;}
	.right-block .top-nav .nav-logo { display: none;}
}

@media screen and (max-width: 767px){
	.left-item > ul > li > a span  { display: none; }
	.left-item {padding-top: 6px;}
	.tellus-wrap { padding: 0;}
	.tellus-wrap h4 { font-size: 22px;}
	.right-block .inner-body { padding: 15px;}
	.right-block .main-body { padding: 75px 0 0;}
	.lr-page .img img { width: 160px}
	.lr-page .heading { text-align: center; margin-top: 30px;}
	.lr-page .link { padding-bottom: 20px; }
	.ciw-head,.radio-type-check, .induistl-list { padding: 0 20px;}
	.ciw-single h2 {font-size: 22px;font-size: 15px;}
	.ciw-head p {margin: 0 0 20px;}
	p, body {font-size: 13px;}
	.perf-right p { font-size: 12px;}
	.sb-single > p { line-height: 1.2; font-size: 13px;}
	.sc-bottom {padding: 10px 15px;margin: 0 -20px;}
	.right-content p {line-height: 1.2; font-size: 13px;}
	.right-content p.small-text { font-size: 12px; margin-top: 10px;}
	.sc-left { width: 70%;}
	.sc-right {width: 30%;}
	.sinlge-cart { width: 50%; margin-bottom: 20px;}
	.main-pop, .pop-left { padding: 10px;}
	.pop-left ul li + li { margin-top: 8px;}
	/*.pop-head { padding: 16px 15px;}*/
	.pop-right-inner { padding: 15px;}
	.pop-foot ul li a, .pop-foot ul li button { padding: 7px 30px;}
	/*.pop-foot {padding: 8px 30px;height: 50px;}*/
	.pop-right { padding-bottom: 50px; }
	.pop-left ul li { padding: 10px;}
	.pop-right h2 { margin: 0 0 10px;}
	.popup-wrap { padding: 20px }
	.left-item > ul > li > a { padding: 5px 6px;}
	.right-block .top-nav .right-item .notification-card .noti-btn + .noti-btn {display: none;}
}

@media screen and (max-width: 550px){
	
}
@media screen and (max-width: 575px){
	.left-item { padding-top: 0;}
	.nav-item > .row {
	    flex-direction: row;
	    justify-content: center;
	}

	.right-block .top-nav .right-item {display: block;border-top: 1px solid #4f5092;padding-top: 2px; padding: 5px 0;    min-height: 35px;}
	.left-item > ul { text-align: right;}
	.nav-item { padding-left: 45px;}
	.tellus-wrap ul { padding: 0;}
	.tellus-wrap ul li, .tellus-wrap p { font-size: 14px;}
	.tellus-wrap h4 { font-size: 18px;}
	.lr-page .heading h2 {font-size: 18px;}

	.ciw-head, .radio-type-check, .induistl-list { padding: 0 15px;}
	.single-induistl-list { padding: 0 90px 0 60px;}
	.sc-bottom ul li {width: 100%;/*border-top: 1px solid #eee;padding: 8px 10px;*/}
	.sc-bottom { margin: 0 -15px;}
	.ciw-single {padding: 0 0;}
	.sc-top {padding: 15px 0;}
	.single-induistl-list { padding: 0 0 0 50px;}
	.right-uplist,.with-onoff-btn .minor-imp { position: static; transform: translate(0,0); margin-top: 15px;}
	.with-onoff-btn .minor-imp { margin-bottom: 15px; }
	.with-onoff-btn { padding-right: 0;}
	/*.ciw-single h2 {font-size: 18px;}*/
	.ciw-single + .ciw-single { margin-top: 15px;}
	.sc-right { padding-right: 25px;}
	.sc-left { line-height: 1.2;}
	.sc-left label input[type="checkbox"] ~ span i { top: -1px;}

	.sc-left, .sc-right { width: 100%; }
	.sc-right { margin-top: 15px; }
	.sc-top .sc-left h3 { margin-bottom: 15px; font-size: 15px; }

	.left-img { width: 40px; height: 40px;  border-width: 1px;}
	.rthb-left h1 span { font-size: 20px;}
	.link-rela-carts ul li { width: 100%; }
	.rth-left h4 { width: 100%; margin: 0 0 5px; }
	.rth-left h4 i { margin: 0 0 0 5px;}
	.toggle-pan { top: 30px;}
	.rti-head-top .rth-left {  padding-right: 0;}
	.search-box { width: 100%; margin-top: 5px;}
	.sinlge-cart { width: 100%;}
	.rthb-left h1 { font-size: 12px;}
	.rthb-left h1 span { font-size: 18px;}


	.main-pop {padding: 2px;}
	.pop-left ul li p { margin: 0; white-space: nowrap;font-size: 12px;padding-top: 2px;}
	.pop-left ul li p span {margin-top: -3px;}
	.pop-left,.pop-right { width: 100%;}
	/*.pop-head { padding: 10px; height: 44px;}*/
	.pop-left ul { display: flex;}
	.pop-left ul li + li { margin: 0 0 0 -1px;}
	.main-pop-inner {padding-top: 81px;}
	.pop-left ul li {padding: 5px;width: 33.333%;text-align: center;}
	.pop-left { position: absolute; top: 44px; left: 0;padding: 0;}
	.pop-left ul li p span {width: 26px;height: 26px;line-height: 23px;margin-right: 2px;}
	.pop-right {box-shadow: none;border-top: 1px solid #e0e0e0;	}
	.double-field .single-input { width: 100%;}
	.double-field .single-input + .single-input  { margin-top: 10px; }
	/*.pop-foot { padding: 8px 10px;}*/
	.sc-bottom ul {padding: 10px 0 0;}
	.perf-right {top: -3px;margin: 0;}

	.acs-head,.acs-input { width: 100%; }
	.th-right a { width: 130px;}

	

}